export const HSRMocHisto = [
  {
    slug: 'acheron',
    score: 7.93,
    usage: 42.66,
    role: 'dps'
  },
  {
    slug: 'argenti',
    score: 9.63,
    usage: 1.2,
    role: 'dps'
  },
  {
    slug: 'arlan',
    score: 11,
    usage: 0.02,
    role: 'dps'
  },
  {
    slug: 'asta',
    score: 9.05,
    usage: 1.53,
    role: 'amplifier'
  },
  {
    slug: 'aventurine',
    score: 6.96,
    usage: 41.63,
    role: 'sustain'
  },
  {
    slug: 'bailu',
    score: 8.51,
    usage: 1.55,
    role: 'sustain'
  },
  {
    slug: 'black-swan',
    score: 8.59,
    usage: 14.9,
    role: 'specialist'
  },
  {
    slug: 'blade',
    score: 8.65,
    usage: 2.37,
    role: 'dps'
  },
  {
    slug: 'boothill',
    score: 7.93,
    usage: 7.75,
    role: 'specialist'
  },
  {
    slug: 'bronya',
    score: 7.86,
    usage: 12.87,
    role: 'amplifier'
  },
  {
    slug: 'clara',
    score: 8.31,
    usage: 9.51,
    role: 'dps'
  },
  {
    slug: 'dan-heng',
    score: 11,
    usage: 0.04,
    role: 'dps'
  },
  {
    slug: 'dr-ratio',
    score: 7.9,
    usage: 9.95,
    role: 'dps'
  },
  {
    slug: 'feixiao',
    score: 6.04,
    usage: 41.01,
    role: 'dps'
  },
  {
    slug: 'firefly',
    score: 7.47,
    usage: 68.41,
    role: 'specialist'
  },
  {
    slug: 'fu-xuan',
    score: 7.53,
    usage: 35.31,
    role: 'sustain'
  },
  {
    slug: 'gallagher',
    score: 7.53,
    usage: 67.53,
    role: 'sustain'
  },
  {
    slug: 'gepard',
    score: 8.45,
    usage: 2.1,
    role: 'sustain'
  },
  {
    slug: 'guinaifen',
    score: 9.07,
    usage: 2.89,
    role: 'amplifier'
  },
  {
    slug: 'hanya',
    score: 9.46,
    usage: 0.6,
    role: 'amplifier'
  },
  {
    slug: 'herta',
    score: 10,
    usage: 0.36,
    role: 'dps'
  },
  {
    slug: 'himeko',
    score: 8.83,
    usage: 7.99,
    role: 'dps'
  },
  {
    slug: 'hook',
    score: 11,
    usage: 0.07,
    role: 'dps'
  },
  {
    slug: 'huohuo',
    score: 7.82,
    usage: 21.39,
    role: 'sustain'
  },
  {
    slug: 'imbibitor-lunae',
    score: 8.69,
    usage: 5.41,
    role: 'dps'
  },
  {
    slug: 'jade',
    score: 8.88,
    usage: 2.35,
    role: 'specialist'
  },
  {
    slug: 'jiaoqiu',
    score: 7.43,
    usage: 22.03,
    role: 'amplifier'
  },
  {
    slug: 'jing-yuan',
    score: 8.87,
    usage: 1.29,
    role: 'dps'
  },
  {
    slug: 'jingliu',
    score: 9.24,
    usage: 4.25,
    role: 'dps'
  },
  {
    slug: 'kafka',
    score: 8.76,
    usage: 13.5,
    role: 'specialist'
  },
  {
    slug: 'lingsha',
    score: 6.16,
    usage: 31.02,
    role: 'sustain',
    new: true
  },
  {
    slug: 'luka',
    score: 10.36,
    usage: 0.32,
    role: 'specialist'
  },
  {
    slug: 'luocha',
    score: 8.04,
    usage: 13.19,
    role: 'sustain'
  },
  {
    slug: 'lynx',
    score: 8.22,
    usage: 1.48,
    role: 'sustain'
  },
  {
    slug: 'march-7th',
    score: 10.42,
    usage: 0.23,
    role: 'sustain'
  },
  {
    slug: 'march-7th-swordmaster',
    score: 6.74,
    usage: 21.12,
    role: 'specialist'
  },
  {
    slug: 'misha',
    score: 11,
    usage: 0.08,
    role: 'dps'
  },
  {
    slug: 'moze',
    score: 5.88,
    usage: 3.74,
    role: 'specialist'
  },
  {
    slug: 'natasha',
    score: 10.24,
    usage: 0.16,
    role: 'sustain'
  },
  {
    slug: 'pela',
    score: 7.88,
    usage: 24.52,
    role: 'amplifier'
  },
  {
    slug: 'qingque',
    score: 8.94,
    usage: 0.51,
    role: 'dps'
  },
  {
    slug: 'robin',
    score: 6.68,
    usage: 48.75,
    role: 'amplifier'
  },
  {
    slug: 'ruan-mei',
    score: 7.6,
    usage: 88.1,
    role: 'amplifier'
  },
  {
    slug: 'sampo',
    score: 10.2,
    usage: 0.41,
    role: 'specialist'
  },
  {
    slug: 'seele',
    score: 9.28,
    usage: 1.07,
    role: 'dps'
  },
  {
    slug: 'serval',
    score: 11,
    usage: 0.05,
    role: 'dps'
  },
  {
    slug: 'silver-wolf',
    score: 8.17,
    usage: 17.58,
    role: 'amplifier'
  },
  {
    slug: 'sparkle',
    score: 8.02,
    usage: 29.09,
    role: 'amplifier'
  },
  {
    slug: 'sushang',
    score: 9.41,
    usage: 0.23,
    role: 'specialist'
  },
  {
    slug: 'tingyun',
    score: 7.8,
    usage: 11.96,
    role: 'amplifier'
  },
  {
    slug: 'topaz',
    score: 6.62,
    usage: 26.44,
    role: 'specialist'
  },
  {
    slug: 'trailblazer-fire',
    score: 11,
    usage: 0.03,
    role: 'specialist'
  },
  {
    slug: 'trailblazer-imaginary',
    score: 7.57,
    usage: 77.4,
    role: 'amplifier'
  },
  {
    slug: 'trailblazer-physical',
    score: 11,
    usage: 0.0,
    role: 'dps'
  },
  {
    slug: 'welt',
    score: 9.28,
    usage: 0.71,
    role: 'specialist'
  },
  {
    slug: 'xueyi',
    score: 11,
    usage: 0.16,
    role: 'specialist'
  },
  {
    slug: 'yanqing',
    score: 10.24,
    usage: 0.18,
    role: 'dps'
  },
  {
    slug: 'yukong',
    score: 11,
    usage: 0.11,
    role: 'amplifier'
  },
  {
    slug: 'yunli',
    score: 7.23,
    usage: 12.98,
    role: 'dps'
  }
];
