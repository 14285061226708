export const MoCCurrent12_1 = [
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 36.37,
    rank: 1,
    avg_round: 6.34,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 16.21,
    rank: 2,
    avg_round: 5.41,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 5.05,
    rank: 3,
    avg_round: 7.09,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 1.41,
    rank: 4,
    avg_round: 7.17,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 1.38,
    rank: 5,
    avg_round: 4.09,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 1.23,
    rank: 6,
    avg_round: 7.19,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 1.16,
    rank: 7,
    avg_round: 7.31,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 1.13,
    rank: 8,
    avg_round: 7.39,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 1.1,
    rank: 9,
    avg_round: 7.03,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.83,
    rank: 10,
    avg_round: 6.93,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.78,
    rank: 11,
    avg_round: 5.83,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.7,
    rank: 12,
    avg_round: 7.63,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.64,
    rank: 13,
    avg_round: 8.6,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.63,
    rank: 14,
    avg_round: 7.8,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.58,
    rank: 15,
    avg_round: 5.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.53,
    rank: 16,
    avg_round: 7.71,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.53,
    rank: 16,
    avg_round: 9.22,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.46,
    rank: 18,
    avg_round: 7.2,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.45,
    rank: 19,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.41,
    rank: 20,
    avg_round: 8.11,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.41,
    rank: 20,
    avg_round: 9.4,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.4,
    rank: 22,
    avg_round: 8.05,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.38,
    rank: 23,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.36,
    rank: 24,
    avg_round: 7.54,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Gallagher',
    app_rate: 0.33,
    rank: 25,
    avg_round: 8.4,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Huohuo',
    app_rate: 0.33,
    rank: 25,
    avg_round: 8.88,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.32,
    rank: 27,
    avg_round: 7.91,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.32,
    rank: 27,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.31,
    rank: 29,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.3,
    rank: 30,
    avg_round: 9.75,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.3,
    rank: 30,
    avg_round: 8.32,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.3,
    rank: 30,
    avg_round: 7.54,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.29,
    rank: 33,
    avg_round: 8.14,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.28,
    rank: 34,
    avg_round: 7.62,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.25,
    rank: 35,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.24,
    rank: 36,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.24,
    rank: 36,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.23,
    rank: 38,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.21,
    rank: 39,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.21,
    rank: 39,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.21,
    rank: 39,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.2,
    rank: 42,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.2,
    rank: 42,
    avg_round: 6.14,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.2,
    rank: 42,
    avg_round: 5.79,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.19,
    rank: 45,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Guinaifen',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.19,
    rank: 45,
    avg_round: 8.7,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.19,
    rank: 45,
    avg_round: 7.68,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Luocha',
    app_rate: 0.19,
    rank: 45,
    avg_round: 9.43,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.18,
    rank: 49,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.18,
    rank: 49,
    avg_round: 8.6,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.16,
    rank: 51,
    avg_round: 7.88,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.15,
    rank: 52,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.14,
    rank: 53,
    avg_round: 6.62,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.14,
    rank: 53,
    avg_round: 7.56,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.14,
    rank: 53,
    avg_round: 7.75,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 53,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.14,
    rank: 53,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Aventurine',
    app_rate: 0.14,
    rank: 53,
    avg_round: 8.6,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'ruan-mei',
    app_rate: 0.14,
    rank: 53,
    avg_round: 3.86,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 53,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.14,
    rank: 53,
    avg_round: 8.22,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 53,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.14,
    rank: 53,
    avg_round: 8.8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.14,
    rank: 53,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.13,
    rank: 65,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.13,
    rank: 65,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.13,
    rank: 65,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 65,
    avg_round: 8.44,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.13,
    rank: 65,
    avg_round: 9.4,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.13,
    rank: 65,
    avg_round: 9.14,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.12,
    rank: 71,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.12,
    rank: 71,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.12,
    rank: 71,
    avg_round: 7.0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.12,
    rank: 71,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.11,
    rank: 75,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.11,
    rank: 75,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Huohuo',
    app_rate: 0.11,
    rank: 75,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.1,
    rank: 78,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.1,
    rank: 78,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Lingsha',
    app_rate: 0.1,
    rank: 78,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.1,
    rank: 78,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.1,
    rank: 78,
    avg_round: 9.11,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 83,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.09,
    rank: 83,
    avg_round: 7.75,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Lingsha',
    app_rate: 0.09,
    rank: 83,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 83,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.09,
    rank: 83,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 83,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 83,
    avg_round: 6.25,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.09,
    rank: 83,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.08,
    rank: 91,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 91,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 91,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 91,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 91,
    avg_round: 6.75,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 91,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Sparkle',
    app_rate: 0.08,
    rank: 91,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.08,
    rank: 91,
    avg_round: 8.29,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.08,
    rank: 91,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 91,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 91,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 91,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 91,
    avg_round: 6.6,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.08,
    rank: 91,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 91,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 91,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 91,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 91,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.08,
    rank: 91,
    avg_round: 2.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 91,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 111,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 111,
    avg_round: 9.12,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 111,
    avg_round: 8.0,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 111,
    avg_round: 8.8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 111,
    avg_round: 8.17,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 111,
    avg_round: 7.14,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.07,
    rank: 111,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.07,
    rank: 111,
    avg_round: 7.75,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 111,
    avg_round: 6.83,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 111,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.07,
    rank: 111,
    avg_round: 6.4,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 111,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.07,
    rank: 111,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 111,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 125,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 125,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.06,
    rank: 125,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 125,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 125,
    avg_round: 8.0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 125,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Guinaifen',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 125,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 125,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 125,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 125,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 135,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 135,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 135,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Guinaifen',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 135,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 135,
    avg_round: 9.25,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 135,
    avg_round: 8.8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 135,
    avg_round: 9.75,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 135,
    avg_round: 8.6,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 135,
    avg_round: 6.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Guinaifen',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 135,
    avg_round: 9.6,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.05,
    rank: 135,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 135,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 135,
    avg_round: 5.67,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.05,
    rank: 135,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 135,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 135,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 135,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 135,
    avg_round: 8.4,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.05,
    rank: 135,
    avg_round: 8.6,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 154,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Gallagher',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 154,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 154,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 154,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 154,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 154,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Bailu',
    app_rate: 0.04,
    rank: 154,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 154,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 154,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 154,
    avg_round: 6.75,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Guinaifen',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 154,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 154,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 154,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 154,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 154,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 154,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 154,
    avg_round: 7.25,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 154,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 154,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Welt',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 154,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Tingyun',
    app_rate: 0.04,
    rank: 154,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 154,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 154,
    avg_round: 8.0,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 154,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 154,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Moze',
    char_two: 'dr-ratio',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 154,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 154,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Sushang',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 181,
    avg_round: 7.75,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Moze',
    char_two: 'dr-ratio',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 181,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Himeko',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 181,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 181,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 181,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 181,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 181,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 181,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 181,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 181,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 181,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 181,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.03,
    rank: 181,
    avg_round: 0.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Guinaifen',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 181,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 181,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Robin',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Acheron',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 181,
    avg_round: 8.33,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 181,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 6.75,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 181,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 181,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 181,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Lynx',
    app_rate: 0.03,
    rank: 181,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 181,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 181,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Asta',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 181,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 181,
    avg_round: 8.25,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 181,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 181,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 181,
    avg_round: 8.25,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Guinaifen',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 181,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-imaginary',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 181,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'ruan-mei',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 181,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 181,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 181,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 181,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-imaginary',
    char_two: 'ruan-mei',
    char_three: 'Gallagher',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Robin',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 181,
    avg_round: 10.0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 181,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Welt',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 181,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 181,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Asta',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Welt',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 293,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Guinaifen',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'silver-wolf',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Welt',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 293,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Hook',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Robin',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Guinaifen',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 293,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Hanya',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Bronya',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 293,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Hanya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 293,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 293,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Boothill',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 293,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 293,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 293,
    avg_round: 1.5,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Boothill',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 293,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 293,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Guinaifen',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 293,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Himeko',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 293,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Gallagher',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 293,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Guinaifen',
    char_two: 'trailblazer-imaginary',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Acheron',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'Asta',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 293,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 293,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Asta',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Jiaoqiu',
    char_four: 'Pela',
    app_rate: 0.02,
    rank: 293,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'silver-wolf',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-imaginary',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 293,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 293,
    avg_round: 99.99,
    star_num: '4'
  }
];
