export const MoCCharacterData = [
  {
    char: 'acheron',
    name: 'Acheron',
    rarity: 'Limited 5*',
    current_app_rate: 25.79,
    prev_app_rate: 49.44,
    round_eidolons: 5.71,
    current_avg_round: 7.98,
    prev_avg_round: 7.73
  },
  {
    char: 'argenti',
    name: 'Argenti',
    rarity: 'Limited 5*',
    current_app_rate: 0.08,
    prev_app_rate: 0.72,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 9.06
  },
  {
    char: 'arlan',
    name: 'Arlan',
    rarity: '4*',
    current_app_rate: 0.01,
    prev_app_rate: 0.03,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'asta',
    name: 'Asta',
    rarity: '4*',
    current_app_rate: 1.41,
    prev_app_rate: 0.63,
    round_eidolons: 6.35,
    current_avg_round: 8.93,
    prev_avg_round: 9.5
  },
  {
    char: 'aventurine',
    name: 'Aventurine',
    rarity: 'Limited 5*',
    current_app_rate: 43.34,
    prev_app_rate: 41.05,
    round_eidolons: 4.28,
    current_avg_round: 6.08,
    prev_avg_round: 7.16
  },
  {
    char: 'bailu',
    name: 'Bailu',
    rarity: 'Standard 5*',
    current_app_rate: 1.13,
    prev_app_rate: 1.78,
    round_eidolons: 7.28,
    current_avg_round: 8.09,
    prev_avg_round: 8.88
  },
  {
    char: 'black-swan',
    name: 'Black Swan',
    rarity: 'Limited 5*',
    current_app_rate: 6.41,
    prev_app_rate: 19.66,
    round_eidolons: 7.34,
    current_avg_round: 8.88,
    prev_avg_round: 8.5
  },
  {
    char: 'blade',
    name: 'Blade',
    rarity: 'Limited 5*',
    current_app_rate: 1.43,
    prev_app_rate: 4.46,
    round_eidolons: 8.4,
    current_avg_round: 9.35,
    prev_avg_round: 8.32
  },
  {
    char: 'boothill',
    name: 'Boothill',
    rarity: 'Limited 5*',
    current_app_rate: 4.95,
    prev_app_rate: 9.67,
    round_eidolons: 6.15,
    current_avg_round: 7.57,
    prev_avg_round: 7.66
  },
  {
    char: 'bronya',
    name: 'Bronya',
    rarity: 'Standard 5*',
    current_app_rate: 7.91,
    prev_app_rate: 16.55,
    round_eidolons: 5.1,
    current_avg_round: 7.6,
    prev_avg_round: 8.11
  },
  {
    char: 'clara',
    name: 'Clara',
    rarity: 'Standard 5*',
    current_app_rate: 4.59,
    prev_app_rate: 6.23,
    round_eidolons: 6.96,
    current_avg_round: 8.49,
    prev_avg_round: 8.64
  },
  {
    char: 'dan-heng',
    name: 'Dan Heng',
    rarity: '4*',
    current_app_rate: 0.03,
    prev_app_rate: 0.06,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'dr-ratio',
    name: 'Dr. Ratio',
    rarity: 'Limited 5*',
    current_app_rate: 2.94,
    prev_app_rate: 12.85,
    round_eidolons: 6.92,
    current_avg_round: 8.42,
    prev_avg_round: 8.27
  },
  {
    char: 'feixiao',
    name: 'Feixiao',
    rarity: 'Limited 5*',
    current_app_rate: 59.65,
    prev_app_rate: 22.37,
    round_eidolons: 3.95,
    current_avg_round: 6.01,
    prev_avg_round: 6.07
  },
  {
    char: 'firefly',
    name: 'Firefly',
    rarity: 'Limited 5*',
    current_app_rate: 75.44,
    prev_app_rate: 53.38,
    round_eidolons: 4.79,
    current_avg_round: 6.56,
    prev_avg_round: 8
  },
  {
    char: 'fu-xuan',
    name: 'Fu Xuan',
    rarity: 'Limited 5*',
    current_app_rate: 27.58,
    prev_app_rate: 40.44,
    round_eidolons: 5.83,
    current_avg_round: 7,
    prev_avg_round: 7.55
  },
  {
    char: 'gallagher',
    name: 'Gallagher',
    rarity: '4*',
    current_app_rate: 64.57,
    prev_app_rate: 58.88,
    round_eidolons: 5.44,
    current_avg_round: 6.85,
    prev_avg_round: 7.87
  },
  {
    char: 'gepard',
    name: 'Gepard',
    rarity: 'Standard 5*',
    current_app_rate: 1.38,
    prev_app_rate: 2.52,
    round_eidolons: 6.57,
    current_avg_round: 8.41,
    prev_avg_round: 8.03
  },
  {
    char: 'guinaifen',
    name: 'Guinaifen',
    rarity: '4*',
    current_app_rate: 1.89,
    prev_app_rate: 0.77,
    round_eidolons: 7.76,
    current_avg_round: 9.31,
    prev_avg_round: 9.04
  },
  {
    char: 'hanya',
    name: 'Hanya',
    rarity: '4*',
    current_app_rate: 0.22,
    prev_app_rate: 0.68,
    round_eidolons: 7.29,
    current_avg_round: 99.99,
    prev_avg_round: 8.32
  },
  {
    char: 'herta',
    name: 'Herta',
    rarity: '4*',
    current_app_rate: 0.04,
    prev_app_rate: 0.22,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'himeko',
    name: 'Himeko',
    rarity: 'Standard 5*',
    current_app_rate: 12.75,
    prev_app_rate: 0.2,
    round_eidolons: 5.47,
    current_avg_round: 7.26,
    prev_avg_round: 99.99
  },
  {
    char: 'hook',
    name: 'Hook',
    rarity: '4*',
    current_app_rate: 0.13,
    prev_app_rate: 0.03,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'huohuo',
    name: 'Huohuo',
    rarity: 'Limited 5*',
    current_app_rate: 15.88,
    prev_app_rate: 29.53,
    round_eidolons: 5.98,
    current_avg_round: 7.33,
    prev_avg_round: 7.99
  },
  {
    char: 'imbibitor-lunae',
    name: 'Imbibitor Lunae',
    rarity: 'Limited 5*',
    current_app_rate: 2.94,
    prev_app_rate: 10.35,
    round_eidolons: 7.53,
    current_avg_round: 9.26,
    prev_avg_round: 8.15
  },
  {
    char: 'jade',
    name: 'Jade',
    rarity: 'Limited 5*',
    current_app_rate: 1.14,
    prev_app_rate: 2.25,
    round_eidolons: 5.62,
    current_avg_round: 99.99,
    prev_avg_round: 8.25
  },
  {
    char: 'jiaoqiu',
    name: 'Jiaoqiu',
    rarity: 'Limited 5*',
    current_app_rate: 18.63,
    prev_app_rate: 25.42,
    round_eidolons: 5.05,
    current_avg_round: 7.67,
    prev_avg_round: 7.18
  },
  {
    char: 'jing-yuan',
    name: 'Jing Yuan',
    rarity: 'Limited 5*',
    current_app_rate: 0.56,
    prev_app_rate: 0.88,
    round_eidolons: 7.86,
    current_avg_round: 9.11,
    prev_avg_round: 8.36
  },
  {
    char: 'jingliu',
    name: 'Jingliu',
    rarity: 'Limited 5*',
    current_app_rate: 0.37,
    prev_app_rate: 6.13,
    round_eidolons: 5.78,
    current_avg_round: 99.99,
    prev_avg_round: 9.11
  },
  {
    char: 'kafka',
    name: 'Kafka',
    rarity: 'Limited 5*',
    current_app_rate: 5.44,
    prev_app_rate: 16.07,
    round_eidolons: 7.39,
    current_avg_round: 9.03,
    prev_avg_round: 8.66
  },
  {
    char: 'lingsha',
    name: 'Lingsha',
    rarity: 'Limited 5*',
    current_app_rate: 31.02,
    prev_app_rate: 31.02,
    round_eidolons: 4.23,
    current_avg_round: 6.16,
    prev_avg_round: 6.16
  },
  {
    char: 'luka',
    name: 'Luka',
    rarity: '4*',
    current_app_rate: 0.03,
    prev_app_rate: 0.4,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 9.07
  },
  {
    char: 'luocha',
    name: 'Luocha',
    rarity: 'Limited 5*',
    current_app_rate: 9.3,
    prev_app_rate: 16.33,
    round_eidolons: 6.57,
    current_avg_round: 7.58,
    prev_avg_round: 8.27
  },
  {
    char: 'lynx',
    name: 'Lynx',
    rarity: '4*',
    current_app_rate: 0.64,
    prev_app_rate: 2.34,
    round_eidolons: 7.77,
    current_avg_round: 8.21,
    prev_avg_round: 8.38
  },
  {
    char: 'march-7th',
    name: 'March 7th',
    rarity: '4*',
    current_app_rate: 0.09,
    prev_app_rate: 0.29,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 9.25
  },
  {
    char: 'march-7th-swordmaster',
    name: 'March 7th - The Hunt',
    rarity: '4*',
    current_app_rate: 25.4,
    prev_app_rate: 16.84,
    round_eidolons: 4.88,
    current_avg_round: 6.63,
    prev_avg_round: 6.84
  },
  {
    char: 'misha',
    name: 'Misha',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0.2,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'moze',
    name: 'Moze',
    rarity: '4*',
    current_app_rate: 5,
    prev_app_rate: 2.48,
    round_eidolons: 4.71,
    current_avg_round: 6.28,
    prev_avg_round: 5.48
  },
  {
    char: 'natasha',
    name: 'Natasha',
    rarity: '4*',
    current_app_rate: 0.11,
    prev_app_rate: 0.17,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 8.71
  },
  {
    char: 'pela',
    name: 'Pela',
    rarity: '4*',
    current_app_rate: 9.37,
    prev_app_rate: 30.51,
    round_eidolons: 6.71,
    current_avg_round: 7.91,
    prev_avg_round: 7.76
  },
  {
    char: 'qingque',
    name: 'Qingque',
    rarity: '4*',
    current_app_rate: 0.18,
    prev_app_rate: 0.52,
    round_eidolons: 6.47,
    current_avg_round: 99.99,
    prev_avg_round: 8.73
  },
  {
    char: 'robin',
    name: 'Robin',
    rarity: 'Limited 5*',
    current_app_rate: 71.17,
    prev_app_rate: 45.4,
    round_eidolons: 4.26,
    current_avg_round: 6.1,
    prev_avg_round: 6.69
  },
  {
    char: 'ruan-mei',
    name: 'Ruan Mei',
    rarity: 'Limited 5*',
    current_app_rate: 90.81,
    prev_app_rate: 79.47,
    round_eidolons: 4.98,
    current_avg_round: 6.73,
    prev_avg_round: 8.11
  },
  {
    char: 'sampo',
    name: 'Sampo',
    rarity: '4*',
    current_app_rate: 0.2,
    prev_app_rate: 0.89,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 8.61
  },
  {
    char: 'seele',
    name: 'Seele',
    rarity: 'Limited 5*',
    current_app_rate: 0.42,
    prev_app_rate: 1.06,
    round_eidolons: 7.8,
    current_avg_round: 99.99,
    prev_avg_round: 8.6
  },
  {
    char: 'serval',
    name: 'Serval',
    rarity: '4*',
    current_app_rate: 0.02,
    prev_app_rate: 0.02,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'silver-wolf',
    name: 'Silver Wolf',
    rarity: 'Limited 5*',
    current_app_rate: 6.86,
    prev_app_rate: 17.75,
    round_eidolons: 7.45,
    current_avg_round: 8.47,
    prev_avg_round: 8.22
  },
  {
    char: 'sparkle',
    name: 'Sparkle',
    rarity: 'Limited 5*',
    current_app_rate: 19.35,
    prev_app_rate: 36.21,
    round_eidolons: 5.36,
    current_avg_round: 7.85,
    prev_avg_round: 8.11
  },
  {
    char: 'sushang',
    name: 'Sushang',
    rarity: '4*',
    current_app_rate: 0.07,
    prev_app_rate: 0.17,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 8.36
  },
  {
    char: 'tingyun',
    name: 'Tingyun',
    rarity: '4*',
    current_app_rate: 6.53,
    prev_app_rate: 18.28,
    round_eidolons: 6.36,
    current_avg_round: 7.59,
    prev_avg_round: 7.53
  },
  {
    char: 'topaz',
    name: 'Topaz & Numby',
    rarity: 'Limited 5*',
    current_app_rate: 37.57,
    prev_app_rate: 19.77,
    round_eidolons: 4.05,
    current_avg_round: 5.84,
    prev_avg_round: 6.73
  },
  {
    char: 'trailblazer-fire',
    name: 'Fire Trailblazer',
    rarity: '4*',
    current_app_rate: 0.02,
    prev_app_rate: 0.01,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'trailblazer-imaginary',
    name: 'Imaginary Trailblazer',
    rarity: '4*',
    current_app_rate: 86.53,
    prev_app_rate: 59.78,
    round_eidolons: 4.91,
    current_avg_round: 6.7,
    prev_avg_round: 8.07
  },
  {
    char: 'trailblazer-physical',
    name: 'Physical Trailblazer',
    rarity: '4*',
    current_app_rate: 0,
    prev_app_rate: 0,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'welt',
    name: 'Welt',
    rarity: 'Standard 5*',
    current_app_rate: 0.5,
    prev_app_rate: 0.63,
    round_eidolons: 7.88,
    current_avg_round: 9.22,
    prev_avg_round: 9.12
  },
  {
    char: 'xueyi',
    name: 'Xueyi',
    rarity: '4*',
    current_app_rate: 0.1,
    prev_app_rate: 0.13,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'yanqing',
    name: 'Yanqing',
    rarity: 'Standard 5*',
    current_app_rate: 0.01,
    prev_app_rate: 0.47,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 8.71
  },
  {
    char: 'yukong',
    name: 'Yukong',
    rarity: '4*',
    current_app_rate: 0.05,
    prev_app_rate: 0.28,
    round_eidolons: 99.99,
    current_avg_round: 99.99,
    prev_avg_round: 99.99
  },
  {
    char: 'yunli',
    name: 'Yunli',
    rarity: 'Limited 5*',
    current_app_rate: 9.77,
    prev_app_rate: 16.18,
    round_eidolons: 5,
    current_avg_round: 7.17,
    prev_avg_round: 7.28
  }
];
