export const MoCCurrent11_2 = [
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 37.99,
    rank: 1,
    avg_round: 4.92,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 19.89,
    rank: 2,
    avg_round: 4.43,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 2.23,
    rank: 3,
    avg_round: 6.91,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 1.75,
    rank: 4,
    avg_round: 6.1,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 1.29,
    rank: 5,
    avg_round: 6.9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 1.22,
    rank: 6,
    avg_round: 6.29,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.93,
    rank: 7,
    avg_round: 6.39,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.91,
    rank: 8,
    avg_round: 6.47,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.72,
    rank: 9,
    avg_round: 7.32,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.69,
    rank: 10,
    avg_round: 6.13,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.68,
    rank: 11,
    avg_round: 8.4,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.61,
    rank: 12,
    avg_round: 6.79,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.61,
    rank: 12,
    avg_round: 6.83,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.57,
    rank: 14,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.57,
    rank: 14,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.56,
    rank: 16,
    avg_round: 6.89,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.56,
    rank: 16,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.55,
    rank: 18,
    avg_round: 6.66,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.47,
    rank: 19,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.45,
    rank: 20,
    avg_round: 7.81,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.44,
    rank: 21,
    avg_round: 5.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.4,
    rank: 22,
    avg_round: 6.82,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.36,
    rank: 23,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.35,
    rank: 24,
    avg_round: 7.37,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.32,
    rank: 25,
    avg_round: 7.59,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.31,
    rank: 26,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.3,
    rank: 27,
    avg_round: 7.65,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'fu-xuan',
    app_rate: 0.28,
    rank: 28,
    avg_round: 8.89,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.27,
    rank: 29,
    avg_round: 8.43,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.26,
    rank: 30,
    avg_round: 7.4,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.25,
    rank: 31,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.24,
    rank: 32,
    avg_round: 8.58,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.24,
    rank: 32,
    avg_round: 7.42,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.24,
    rank: 32,
    avg_round: 6.29,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.23,
    rank: 35,
    avg_round: 7.11,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.23,
    rank: 35,
    avg_round: 7.21,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.23,
    rank: 35,
    avg_round: 7.12,
    star_num: '4'
  },
  {
    char_one: 'Moze',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.22,
    rank: 38,
    avg_round: 6.71,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.21,
    rank: 39,
    avg_round: 6.65,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.19,
    rank: 40,
    avg_round: 7.83,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.17,
    rank: 41,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.17,
    rank: 41,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.16,
    rank: 43,
    avg_round: 8.15,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.15,
    rank: 44,
    avg_round: 5.27,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Gallagher',
    app_rate: 0.14,
    rank: 45,
    avg_round: 8.57,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 45,
    avg_round: 8.09,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.14,
    rank: 45,
    avg_round: 6.75,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.14,
    rank: 45,
    avg_round: 7.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 45,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Luocha',
    app_rate: 0.14,
    rank: 45,
    avg_round: 7.69,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.14,
    rank: 45,
    avg_round: 6.57,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Huohuo',
    app_rate: 0.14,
    rank: 45,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.14,
    rank: 45,
    avg_round: 7.38,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.14,
    rank: 45,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.14,
    rank: 45,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.13,
    rank: 56,
    avg_round: 7.4,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.13,
    rank: 56,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.13,
    rank: 56,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.13,
    rank: 56,
    avg_round: 5.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Sparkle',
    app_rate: 0.13,
    rank: 56,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.13,
    rank: 56,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.12,
    rank: 62,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.12,
    rank: 62,
    avg_round: 5.57,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.12,
    rank: 62,
    avg_round: 8.38,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.12,
    rank: 62,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.12,
    rank: 62,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.11,
    rank: 67,
    avg_round: 7.8,
    star_num: '4'
  },
  {
    char_one: 'Guinaifen',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.11,
    rank: 67,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.11,
    rank: 67,
    avg_round: 5.25,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.11,
    rank: 67,
    avg_round: 7.71,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.11,
    rank: 67,
    avg_round: 6.0,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.1,
    rank: 72,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 73,
    avg_round: 7.75,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 73,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 73,
    avg_round: 6.56,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.09,
    rank: 73,
    avg_round: 5.0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 73,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.09,
    rank: 73,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 73,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.09,
    rank: 73,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 73,
    avg_round: 5.6,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.09,
    rank: 73,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.09,
    rank: 73,
    avg_round: 6.33,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 84,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Sparkle',
    app_rate: 0.08,
    rank: 84,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 84,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 84,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 84,
    avg_round: 5.75,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 84,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 84,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 84,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.08,
    rank: 84,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.08,
    rank: 84,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 84,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.08,
    rank: 84,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.08,
    rank: 84,
    avg_round: 7.0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lynx',
    app_rate: 0.08,
    rank: 84,
    avg_round: 4.75,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.08,
    rank: 84,
    avg_round: 5.0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 84,
    avg_round: 3.67,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.08,
    rank: 84,
    avg_round: 7.25,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.08,
    rank: 84,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.08,
    rank: 84,
    avg_round: 7.75,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.08,
    rank: 84,
    avg_round: 8.2,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.08,
    rank: 84,
    avg_round: 7.75,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 105,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'Moze',
    char_two: 'dr-ratio',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.07,
    rank: 105,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Sparkle',
    app_rate: 0.07,
    rank: 105,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.07,
    rank: 105,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 105,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 105,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 105,
    avg_round: 6.67,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 105,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.07,
    rank: 105,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.07,
    rank: 105,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.07,
    rank: 105,
    avg_round: 9.6,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.07,
    rank: 105,
    avg_round: 9.0,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Bronya',
    char_four: 'Robin',
    app_rate: 0.07,
    rank: 105,
    avg_round: 1,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Sparkle',
    app_rate: 0.07,
    rank: 105,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.07,
    rank: 105,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Moze',
    char_two: 'Feixiao',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 120,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 120,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 120,
    avg_round: 9.2,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.06,
    rank: 120,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 120,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.06,
    rank: 120,
    avg_round: 7.2,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 120,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 120,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.06,
    rank: 120,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 120,
    avg_round: 7.6,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 120,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.06,
    rank: 120,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.06,
    rank: 120,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.06,
    rank: 120,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.06,
    rank: 120,
    avg_round: 7.0,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Qingque',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.06,
    rank: 120,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Gepard',
    app_rate: 0.06,
    rank: 120,
    avg_round: 8.25,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.05,
    rank: 137,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.05,
    rank: 137,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.05,
    rank: 137,
    avg_round: 6.67,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.05,
    rank: 137,
    avg_round: 7.75,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Bronya',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 137,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.05,
    rank: 137,
    avg_round: 6.75,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 137,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 137,
    avg_round: 9.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 137,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 137,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Xueyi',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.05,
    rank: 137,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Moze',
    char_two: 'dr-ratio',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.05,
    rank: 137,
    avg_round: 8.25,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 149,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Jade',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 149,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'topaz',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 149,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Bronya',
    char_four: 'Robin',
    app_rate: 0.04,
    rank: 149,
    avg_round: 0,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 149,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Bronya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 149,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-imaginary',
    char_two: 'ruan-mei',
    char_three: 'Gallagher',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 149,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 149,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 149,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Sparkle',
    app_rate: 0.04,
    rank: 149,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 149,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Gallagher',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 149,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 149,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 149,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'ruan-mei',
    app_rate: 0.04,
    rank: 149,
    avg_round: 2.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 149,
    avg_round: 7.67,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.04,
    rank: 149,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 149,
    avg_round: 6.67,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 149,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 149,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.04,
    rank: 149,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Lingsha',
    app_rate: 0.04,
    rank: 149,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.04,
    rank: 149,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 149,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.04,
    rank: 149,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 149,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.04,
    rank: 149,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.04,
    rank: 149,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 177,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'Robin',
    app_rate: 0.03,
    rank: 177,
    avg_round: 2,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 177,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Guinaifen',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 177,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 177,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Herta',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 5.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Bailu',
    app_rate: 0.03,
    rank: 177,
    avg_round: 8.75,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-imaginary',
    char_two: 'ruan-mei',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Boothill',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 177,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 177,
    avg_round: 6.67,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-imaginary',
    char_two: 'ruan-mei',
    char_three: 'silver-wolf',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 177,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Robin',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 177,
    avg_round: 7.25,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Robin',
    app_rate: 0.03,
    rank: 177,
    avg_round: 3,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 177,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 8.0,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Boothill',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 177,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Moze',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'ruan-mei',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Bailu',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Firefly',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'jing-yuan',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'ruan-mei',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 177,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Blade',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Moze',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 6.33,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 12,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'march-7th-swordmaster',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 177,
    avg_round: 4.67,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9.33,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Xueyi',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Gepard',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Sparkle',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.03,
    rank: 177,
    avg_round: 8.67,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Luocha',
    app_rate: 0.03,
    rank: 177,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'silver-wolf',
    char_four: 'Aventurine',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.03,
    rank: 177,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 286,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Yunli',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Jiaoqiu',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 286,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Xueyi',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 286,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 286,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Jiaoqiu',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 286,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Moze',
    char_two: 'Feixiao',
    char_three: 'march-7th-swordmaster',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 286,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'topaz',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Guinaifen',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Moze',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Acheron',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'topaz',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 286,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Bronya',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Hanya',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 6.5,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Xueyi',
    char_three: 'trailblazer-imaginary',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 286,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Pela',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 286,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Herta',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Xueyi',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'march-7th-swordmaster',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Pela',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'ruan-mei',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Welt',
    char_three: 'Jiaoqiu',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'silver-wolf',
    char_three: 'Tingyun',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 286,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Jiaoqiu',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Moze',
    char_two: 'dr-ratio',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Xueyi',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Blade',
    char_three: 'ruan-mei',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Yunli',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Asta',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-imaginary',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Feixiao',
    char_three: 'Robin',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'silver-wolf',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 286,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'trailblazer-imaginary',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Bailu',
    app_rate: 0.02,
    rank: 286,
    avg_round: 5,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Jiaoqiu',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'silver-wolf',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'trailblazer-imaginary',
    char_three: 'Jiaoqiu',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9.5,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'Jiaoqiu',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'Guinaifen',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Himeko',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'Robin',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'trailblazer-imaginary',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Xueyi',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'ruan-mei',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'ruan-mei',
    char_four: 'silver-wolf',
    app_rate: 0.02,
    rank: 286,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Gallagher',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Asta',
    char_three: 'trailblazer-imaginary',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Firefly',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Xueyi',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 286,
    avg_round: 4,
    star_num: '4'
  },
  {
    char_one: 'dr-ratio',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'ruan-mei',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'Sparkle',
    char_three: 'Tingyun',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 7,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Tingyun',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Jiaoqiu',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'trailblazer-imaginary',
    char_two: 'Jiaoqiu',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 6,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'march-7th-swordmaster',
    char_three: 'topaz',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Jingliu',
    char_three: 'trailblazer-imaginary',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'silver-wolf',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Pela',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Tingyun',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'silver-wolf',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Kafka',
    char_two: 'black-swan',
    char_three: 'Robin',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'topaz',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Feixiao',
    char_two: 'topaz',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Firefly',
    char_two: 'Guinaifen',
    char_three: 'trailblazer-imaginary',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Bronya',
    char_three: 'silver-wolf',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'topaz',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 7.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'Xueyi',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Himeko',
    char_three: 'Jiaoqiu',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8.5,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Guinaifen',
    char_three: 'ruan-mei',
    char_four: 'Gepard',
    app_rate: 0.02,
    rank: 286,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Robin',
    char_three: 'silver-wolf',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Seele',
    char_two: 'Bronya',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Pela',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Herta',
    char_two: 'topaz',
    char_three: 'Robin',
    char_four: 'Aventurine',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Guinaifen',
    char_two: 'trailblazer-imaginary',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'imbibitor-lunae',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Gallagher',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'ruan-mei',
    char_three: 'Sparkle',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Firefly',
    char_three: 'ruan-mei',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Robin',
    char_four: 'Lingsha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Himeko',
    char_two: 'Qingque',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 9,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'black-swan',
    char_four: 'Lynx',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Qingque',
    char_two: 'silver-wolf',
    char_three: 'Sparkle',
    char_four: 'Huohuo',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jingliu',
    char_two: 'Bronya',
    char_three: 'Jiaoqiu',
    char_four: 'Luocha',
    app_rate: 0.02,
    rank: 286,
    avg_round: 8,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'ruan-mei',
    char_three: 'silver-wolf',
    char_four: 'Sparkle',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  },
  {
    char_one: 'Jade',
    char_two: 'Himeko',
    char_three: 'Pela',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 10,
    star_num: '4'
  },
  {
    char_one: 'Acheron',
    char_two: 'Kafka',
    char_three: 'Sparkle',
    char_four: 'fu-xuan',
    app_rate: 0.02,
    rank: 286,
    avg_round: 99.99,
    star_num: '4'
  }
];
